<h1 mat-dialog-title>Edit Category Name</h1>
<form #newCategoryForm="ngForm" novalidate>
  <div mat-dialog-content>
    <div *ngIf="data.hasRules">
      <span>Rules associated</span>
      <input
        *ngIf="rules?.length > 8"
        pInputText
        type="text"
        [(ngModel)]="ruleSearchTerm"
        (input)="searchRule()"
        placeholder="Search Rules"
        class="form-control form-control-sm mb-4"
      />
      <ul class="mt-3 rule-list small">
        <li *ngFor="let rule of displayedRules; trackBy: trackBy">{{ rule }}</li>
      </ul>
    </div>
    <hr />
    <div *ngIf="ppfs?.length">
      <span>PPFs associated</span>
      <input
        *ngIf="ppfs?.length > 8"
        pInputText
        type="text"
        [(ngModel)]="ppfSearchTerm"
        (input)="searchPpf()"
        placeholder="Search PPF"
        class="form-control form-control-sm mb-4"
      />
      <ul class="mt-3 rule-list small">
        <li *ngFor="let rule of displayedPpfs; trackBy: trackBy">{{ rule }}</li>
      </ul>
    </div>
    <button *ngIf="data.hasRules" type="button" class="btn btn-sm btn-info mb-3" (click)="enableEditInput()">
      Rename Category
    </button>
    <div class="form-group">
      <label for="name" *ngIf="!data.hasRules" [disabled]="drgVDisabled && data.isDrgV">Name:</label>
      <input
        type="text"
        class="form-control form-control-sm"
        #categoryName
        name="name"
        [(ngModel)]="data.name"
        required
        (input)="isDirty = true"
        [disabled]="disableEditing || (drgVDisabled && this.data.isDrgV)"
      />
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <textarea
        class="form-control"
        name="description"
        id="description"
        rows="3"
        (input)="isDirty = true"
        [(ngModel)]="data.description"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="verNotes">Version Notes:</label>
      <textarea
        class="form-control"
        name="verNotes"
        id="verNotes"
        rows="2"
        (input)="isDirty = true"
        [(ngModel)]="data.versionEditLog"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="timeDesignation">Time Designation:</label>
      <input
        type="number"
        class="form-control form-control-sm"
        #categoryName
        (input)="isDirty = true"
        name="timeDesignation"
        [(ngModel)]="data.resolverDays"
      />
    </div>
    <h1 mat-dialog-title class="mb-1 mt-4">Service Lines</h1>
    <div class="sl-check-box">
      <p-checkbox
        name="isDrgV"
        [disabled]="drgVDisabled"
        [binary]="true"
        [(ngModel)]="data.isDrgV"
        (onChange)="onServiceLineCheck($event.checked, 'isDrgV')"
      >
      </p-checkbox>
      <label class="sl-check-box-label" for="isDrgV">DrgV</label>
    </div>
    <div class="mt-2 warn-text" *ngIf="drgVDisabled"> 
      Creating or editing new category name has been disabled for DrgV service line to complete parity with Charge Cat AI
    </div>
    <div class="sl-check-box">
      <p-checkbox
        name="isChargeCapture"
        [binary]="true"
        [(ngModel)]="data.isChargeCapture"
        (onChange)="onServiceLineCheck($event.checked, 'isChargeCapture')"
      >
      </p-checkbox>
      <label class="sl-check-box-label" for="isChargeCapture">Charge Capture</label>
    </div>
    <div *ngIf="!drgVDisabled && !this.data.isDrgV && !this.data.isChargeCapture" class="mt-2 error-text">
      At least one service line must be selected
    </div>

    <div *ngIf="drgVDisabled && this.data.isDrgV && this.data.name !== oldCategoryName" class="mt-2 error-text">
      Editing this category name has been disabled to complete parity with Charge Cat AI
    </div>
  </div>
  <div mat-dialog-actions class="float-right">
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-sm btn-primary" (click)="updateName()" [disabled]="!formIsValid() || !isDirty">
      Update
    </button>
  </div>
</form>

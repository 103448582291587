import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ChargeCatStoreFacade } from 'src/app/charge-cat/store/charge-cat-store.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cm-add-category-modal',
  templateUrl: 'add-category-modal.component.html',
  styleUrls: ['add-category-modal.component.scss']
})
export class AddCategoryModalComponent implements OnDestroy, OnInit {
  isDuplicate = false;
  subs = new SubSink();
  drgVDisabled = environment.drgvCategoryCreationDisabled
  /**
   * When the user types in the category name field and stops for a specific
   * amount of time, this subject gets triggered via next(), which then
   * notifies a subscriber (in ngOnInit) to be used for duplicate name checking
   */
  duplicateNameSubject: Subject<string> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public facade: ChargeCatStoreFacade
  ) {}

  ngOnInit() {
    this.subs.sink = this.duplicateNameSubject.pipe(debounceTime(300)).subscribe(() => this.checkForDuplicates());
  }

  /**
   * Handler for when the user types in a name for the category
   */
  onAddingName() {
    this.duplicateNameSubject.next();
  }

  /**
   * Handler for checking if the new category name is a duplicate
   */
  checkForDuplicates() {
    this.isDuplicate = this.facade.isDuplicateCategoryName(this.data.name.trim());
  }

  /**
   * Determines if the form is valid by checking that there is both a value
   * and that it's not a duplicate.
   */
  formIsValid(): boolean {
    return this.data.name.trim() !== '' && !this.isDuplicate && (this.data.isDrgV || this.data.isChargeCapture);
  }

  /**
   * Handler for the Add button.
   * It submits the new value and closes the dialog.
   */
  addCategory() {
    this.data.name = this.data.name.trim();
    this.dialogRef.close(this.data);
  }


  /**
   * Handler for the cancel button.
   * It clears the form and closes the dialog.
   */
  cancel(): void {
    this.data.name = '';
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

import { CellAlignments, ColumnFilter } from 'src/app/_shared/models/column-filter';

export let CdmTableColumns: ColumnFilter[] = [
  {
    field: 'chargeCategoryCommaList',
    header: 'Category',
    width: '350px',
    filterType: 'list',
    listBoxKey: 'docId',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'chargeCategoryIds',
    cbFilterType: 'SATISFIES',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'tenantDisplayName',
    header: 'Tenant',
    width: '200px',
    filterType: 'list',
    listBoxKey: 'tenantName',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'tenantName',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'revenueCode',
    header: 'Rev Code',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'field',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'revenueCode',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Center
  },
  {
    field: 'hcpcs',
    header: 'HCPCS',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'field',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'hcpcs',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Center
  },
  {
    field: 'cpt',
    header: 'CPT',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'field',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'cpt',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Center
  },
  {
    field: 'si',
    header: 'SI',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'value', //value in dictionary
    listBoxDisplay: 'displayName', //key in dictionary
    selectedItems: [],
    uniqueData: [], //dictionary
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'cpt',
    cbFilterField2: 'hcpcs',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Center
  },
  {
    field: 'paymentRate',
    header: 'APC',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'value',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'cpt',
    cbFilterField2: 'hcpcs',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'chargeCode',
    header: 'Charge Code',
    width: '200px',
    filterType: 'text-extra',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'chargeCode',
    cbFilterType: 'LIKE',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'description',
    header: 'Description',
    width: '275px',
    filterType: 'text-extra',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'description',
    cbFilterType: 'LIKE CASE INSENSITIVE',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'homegrownDescription',
    header: 'Homegrown Description',
    width: '275px',
    filterType: 'text-extra',
    listBoxKey: '',
    listBoxDisplay: 'homegrownDescription',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'homegrownDescription',
    cbFilterType: 'LIKE',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'amaDescription',
    header: 'AMA Description',
    width: '275px',
    filterType: 'list',
    listBoxKey: 'value',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'cpt',
    cbFilterField2: 'hcpcs',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'tokenCommaList',
    header: 'Tokens',
    width: '275px',
    filterType: 'text-extra',
    listBoxKey: 'TODO',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'tokens',
    cbFilterType: 'SATISFIESLIKE',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'descriptionType',
    header: 'Type',
    width: '150px',
    filterType: 'list',
    listBoxKey: 'field',
    listBoxDisplay: 'displayName',
    selectedItems: [],
    uniqueData: [],
    uniqueDataNonLazyFilter: [],
    tempSelections: [],
    cbFilterField: 'descriptionType',
    cbFilterType: 'IN',
    cellAlignment: CellAlignments.Left
  },
  {
    field: 'totalDollar',
    header: 'Total Dollar',
    width: '150px',
    filterType: 'text',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'totalDollar',
    cbFilterType: 'EXACT',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'chargeCount',
    header: 'Charge Count',
    width: '165px',
    filterType: 'text',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'chargeCount',
    cbFilterType: 'EXACT',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'avgDollar',
    header: 'Average Dollar',
    width: '165px',
    filterType: 'text',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'avgDollar',
    cbFilterType: 'EXACT',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'avgQuant',
    header: 'Average Quantity',
    width: '180px',
    filterType: 'text',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'avgQuant',
    cbFilterType: 'EXACT',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'totalQuant',
    header: 'Total Quantity',
    width: '165px',
    filterType: 'text',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: [],
    cbFilterField: 'totalQuant',
    cbFilterType: 'EXACT',
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'createdDate',
    header: 'Created Date',
    width: '160px',
    filterType: 'date',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: undefined,
    cbFilterField: 'createdDate',
    cbFilterType: 'BETWEEN',
    dateRange: [],
    cellAlignment: CellAlignments.Right
  },
  {
    field: 'lastModified',
    header: 'Last Modified',
    width: '160px',
    filterType: 'date',
    listBoxKey: '',
    listBoxDisplay: '',
    selectedItems: [],
    uniqueData: [],
    tempSelections: undefined,
    cbFilterField: 'lastModified',
    cbFilterType: 'BETWEEN',
    dateRange: [],
    cellAlignment: CellAlignments.Right
  }
];
